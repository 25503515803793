a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  margin: 0;

  font-size: 18px;
  line-height: 1.5em;
  font-family: monospace;
  text-transform: uppercase;

  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, */
  /* Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */

  background: #b6edff;
  color: #222;
}

.hero {
  position: absolute;
  left: 0;
  top: 0;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('hero.jpg') no-repeat center center;
  background-size: cover;

  z-index: -1;

  /* mix-blend-mode: multiply; */
  /* filter: grayscale(50%); */
}

header {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1em;
  font-weight: 100;

  background-color: black;
  color: #fff;
  display: inline;
  padding: 0.5rem;

  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1em;
  font-weight: 100;
}

footer span {
  background-color: black;
  color: #fff;
  display: inline;
  padding: 0.5rem;

  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
